import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Layout from 'components/Layout';
import hasAuthToken from 'lib/hasAuthToken';
import MailIcon from 'svg/icon-mail.svg';
import PhoneIcon from 'svg/icon-phone.svg';
import ContentHero from '@molecules/ContentHero/ContentHero';
import SelectedCompanies from '@organisms/SelectedCompanies';
import PagepartTextImage from '@organisms/PagepartTextImage';
import Button from 'components/Button';
import SubscriptionItem from '@organisms/SubscriptionItem';
import FeaturesList from '@molecules/FeaturesList/FeaturesList';

const AddJobPostingPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('addJobPosting.pageTitle')}>
      <div className="o-retain o-retain--wall u-margin-top-huge u-margin-bottom-huge">
        <ContentHero
          title="Plaats je vacature op deBanenSite.nl"
          imageSrc="/img/place-vacancy-hero.jpg"
        >
          <p>
            deBanenSite.nl helpt u de juiste kandidaat te krijgen! Dit doen wij
            voor meer dan <strong>30.000</strong> bedrijven in Nederland.
          </p>
          <p>
            Met ons alles-in-één platform bieden wij de complete tool voor uw
            online werving.
          </p>
          <p>
            Naast dat vacatures zichtbaar zijn op deBanenSite.nl, staan uw
            vacatures ook op ons partner netwerk met o.a. LinkedIn, Indeed &
            Google for Jobs.
          </p>
          <div className="c-content-hero__contact-section">
            <Button
              IconLeft={MailIcon}
              label="Boek gratis demo"
              href="mailto:info@debanensite.nl"
            />
            <Button
              IconLeft={PhoneIcon}
              label="072-5744544"
              href="tel:0725744544"
              extraClasses="u-margin-bottom-base c-button--link"
            />
          </div>
        </ContentHero>
        <SelectedCompanies
          title={
            <span>
              Deze bedrijven vinden geschikte kandidaten via deBanenSite.nl
            </span>
          }
          images={[
            { src: '/img/cirfood.png', width: 180, height: 78 },
            { src: '/img/totalenergies.webp', width: 160, height: 126 },
            { src: '/img/schoonderwoerd.webp', width: 180, height: 90 },
            { src: '/img/c&a.jpg', width: 180, height: 110 },
            { src: '/img/varex.png', width: 180, height: 130 },
            { src: '/img/hollandcasino.webp', width: 180, height: 100 },
          ]}
        />
        <PagepartTextImage
          title="CV-database"
          text={
            <FeaturesList
              features={[
                'Onbeperkt toegang tot meer dan 430.000 kandidaten.',
                "Geen anonieme cv's.",
              ]}
            />
          }
          imageSrc="/img/CV-database.png"
          reverse
        />
        <PagepartTextImage
          title="Jobmarketing"
          text={
            <FeaturesList
              features={[
                'Boost uw vacatures optioneel op meer dan 3.000 online wervingskanalen.',
                'Voorgestelde of handmatig gekozen kanalen.',
                'Nationale en internationale websites.',
              ]}
            />
          }
          imageSrc="/img/jobmarketing.png"
        />

        <PagepartTextImage
          title="Sollicitanten beheren"
          text={
            <FeaturesList
              features={[
                'Maak zelf sollicitatie funnels aan via het dashboard.',
                'Plaats notities bij sollicitanten.',
                'Sollicitanten snel en eenvoudig uitnodigen / afwijzen.',
              ]}
            />
          }
          imageSrc="/img/applications-overview.png"
          reverse
        />

        <PagepartTextImage
          title="Werken bij website"
          text={
            <FeaturesList
              features={[
                'Maak optioneel direct uw eigen werken bij website aan.',
                'U beheert alles via 1 platform. Gemakkelijk, eenvoudig en snel.',
              ]}
            />
          }
          imageSrc="/img/whitelabel-website.png"
        />

        <section>
          <ul className="c-subscriptions u-margin-top-large u-margin-top-huge@from-lg o-list-clean">
            <li className="c-subsriptions__list-item">
              <SubscriptionItem
                title="Vacature plaatsen"
                features={[
                  'Vacatures direct in partnernetwerk.',
                  'Dagelijks vacaturealerts naar kandidaten.',
                  'Unieke bedrijfspagina.',
                  'Plaatsing service & vacature check.',
                  'Beheer uw sollicitaties in één dashboard.',
                  'Stel funnels in.',
                  'Toegang tot CV-database.',
                  'Gratis helpdesk.',
                  'Statistieken dashboard.',
                  'Onbeperkte aantal gebruikers.',
                  'Vacatures continue wisselen met vacatureslots.',
                ]}
                pricingOptions={[
                  'Vacatures plaatsen vanaf 10 vacatureslots voor €1.495,00 excl. btw.',
                ]}
                optionsTitle="Prijs:"
                ctaLabel="Boek gratis demo"
              />
            </li>
            <li className="c-subsriptions__list-item">
              <SubscriptionItem
                title="CV-database"
                features={[
                  'Toegang tot meer dan 430.000 kandidaten.',
                  'Geen anonieme cv’s.',
                  'Cv’s direct downloaden.',
                  'Onbeperkt cv’s inzien.',
                ]}
                pricingOptions={[
                  'Toegang vanaf 1 maand voor €499,00 excl. btw.',
                ]}
                optionsTitle="Prijs:"
                ctaLabel="Boek gratis demo"
              />
            </li>
            <li className="c-subsriptions__list-item">
              <SubscriptionItem
                title="Job-marketing"
                features={[
                  'Boost uw vacatures op extra additionele kanalen.',
                  'Keuze uit meer dan 3.000 kanalen.',
                  'Nationaal en Internationaal.',
                  'Keuze uit voorgestelde kanalen passend op uw vacatures.',
                ]}
                pricingOptions={['Vraag een vrijblijvend advies aan.']}
                optionsTitle="Prijs:"
                ctaLabel="Advies aanvragen"
              />
            </li>
            <li className="c-subsriptions__list-item">
              <SubscriptionItem
                title="Werken bij website"
                features={[
                  'Maak direct uw eigen Werken bij website aan met onze template.',
                  'Custom Werken bij site op aanvraag.',
                  'U beheert alles via 1 platform. Gemakkelijk, eenvoudig en snel.',
                ]}
                pricingOptions={['12 maanden vanaf €399,00 excl. btw.']}
                optionsTitle="Prijs:"
                ctaLabel="Boek gratis demo"
              />
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export async function getServerSideProps({ req, res, locale }) {
  if (!hasAuthToken(req)) {
    res.setHeader(
      'Cache-Control',
      'public, max-age=300, s-maxage=300, stale-while-revalidate=600'
    );
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default AddJobPostingPage;
