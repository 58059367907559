import React from 'react';
import Image from 'next/image';

const PagepartTextImage = ({ title, imageSrc, text, reverse }) => (
  <section className="u-margin-bottom-large u-margin-bottom-huge@from-lg">
    <div
      className={`o-layout o-layout--gutter-base o-layout--gutter-large@from-md ${
        reverse ? ' o-layout--reverse' : ''
      }`}
    >
      <div className="o-layout__cell o-layout__cell--fill@from-md">
        <div className="o-retain--lap">
          <h2 className="c-pagepart-text-image__heading">{title}</h2>
          <div className="c-subscription-item__top">{text}</div>
        </div>
      </div>
      <div className="o-layout__cell o-layout__cell--fill@from-md">
        <div className="c-pagepart-text-image__image">
          <Image src={imageSrc} alt="" width={570} height={320} />
        </div>
      </div>
    </div>
  </section>
);

export default PagepartTextImage;
