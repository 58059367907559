import React from 'react';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import PhoneIcon from 'svg/icon-phone.svg';
import MailIcon from 'svg/icon-mail.svg';
import FeaturesList from '@molecules/FeaturesList/FeaturesList';

const SubscriptionItem = ({
  title,
  optionsTitle,
  features,
  pricingOptions,
  ctaLabel,
}) => (
  <div className="c-subscription-item">
    <div className="c-subscription-item__top">
      <h2 className="u-margin-bottom-large c-subscription-item__title">
        {title}
      </h2>
      <FeaturesList features={features} />
    </div>
    <div className="c-subscription-item__contact-section">
      {!!pricingOptions && (
        <>
          <span>{optionsTitle}</span>
          <FeaturesList features={pricingOptions} />
        </>
      )}

      <Button
        IconLeft={MailIcon}
        label={ctaLabel}
        href={`mailto:info@debanensite.nl?subject=Afsluiten abonnement ${title}`}
      />
      <Button
        IconLeft={PhoneIcon}
        label="072-5744544"
        href="tel:0725744544"
        extraClasses="c-button--link c-subscription-item__phone-link"
      />
    </div>
  </div>
);

SubscriptionItem.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array,
  pricingOptions: PropTypes.array,
};

export default SubscriptionItem;
