import React from 'react';
import PropTypes from 'prop-types';

function FeaturesList({ features }) {
  return (
    <ul className="c-features-list">
      {features.map(feature => (
        <li
          key={feature}
          className="c-features-list__item u-margin-bottom-tiny"
        >
          {feature}
        </li>
      ))}
    </ul>
  );
}

FeaturesList.propTypes = {
  features: PropTypes.array,
};

export default FeaturesList;
