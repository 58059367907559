import PropTypes from 'prop-types';
import React from 'react';
import Image from 'next/image';

const SelectedCompanies = ({ title, images }) => (
  <section className="c-selected-companies">
    <h2 className="c-selected-companies__title">{title}</h2>
    <ul className="c-selected-companies__list">
      {images.map(image => (
        <li className="c-selected-companies__list-item" key={image.src}>
          <Image
            src={image.src}
            alt=""
            width={image.width}
            height={image.height}
          />
        </li>
      ))}
    </ul>
  </section>
);

SelectedCompanies.propTypes = {
  title: PropTypes.object,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    })
  ),
};

export default SelectedCompanies;
