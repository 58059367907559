import PropTypes from 'prop-types';
import React from 'react';
import Image from 'next/image';

const ContentHero = ({ title, children, imageSrc }) => (
  <section className="u-margin-bottom-large">
    <div className="o-layout o-layout--gutter-base">
      <div className="o-layout__cell o-layout__cell--fill@from-md">
        <div className="o-retain--lap c-content-hero__text">
          <h1>{title}</h1>
          {children}
        </div>
      </div>
      <div className="o-layout__cell o-layout__cell--fill@from-md">
        <div className="c-content-hero__image">
          <Image src={imageSrc} alt="" width={604} height={427} />
        </div>
      </div>
    </div>
  </section>
);

ContentHero.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  imageSrc: PropTypes.string,
};

export default ContentHero;
